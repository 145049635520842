import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController, NavController } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-mapa-reportes',
  templateUrl: './mapa-reportes.page.html',
  styleUrls: ['./mapa-reportes.page.scss'],
})
export class MapaReportesPage implements OnInit {

  imei:any = null;
  inicial:any =  null;
  final:any =  null;
  objeto:any = {};
  type:any = "mapa";
  map: Leaflet.Map;
  lat: number = 21.9934775;
  lng: number = -99.0176878;
  polilineaData:any = [];
  linea:any;
  numPlayInicial:number = 0;
  interval:any;
  intervalTimepo:number = 2000;
  play:boolean = false;
  stop:boolean = false;
  pause:boolean = false;
  historial:any = [];
  numRuta:number = 0;
  marcaPlay:any = null;

  constructor( private route:ActivatedRoute, private _api:ApiService, public loadingCtrl:LoadingController, 
              public navCtrl:NavController, public alertCtrl:AlertController) { 
    this.route.params.subscribe((params:any)=>{
      console.log("params",params);
      this.imei    = params.id
      this.inicial = params.inicial;
      this.final = params.final;

      localStorage.setItem("imei", this.imei);
      localStorage.setItem("inicial", this.inicial);
      localStorage.setItem("final", this.final);

      this.getObjeto();
    }); 
  }

  ngOnInit() {
    this.leafletMap();
    setTimeout(() => {      
      this.map.invalidateSize();
      this.historialObjeto();
    }, 500);
  }

  ngOnDestroy(){    
    clearInterval(this.inicial);
  }

  tiempoReproduccion(){

    if(this.interval){
      clearInterval(this.interval);
    }

    this.interval = setInterval(()=>{
      console.log(this.historial[this.numRuta]); 
      
      if( !this.historial[this.numRuta] ){
        clearInterval(this.interval);
        return;
      }

      if(this.marcaPlay){
        this.map.removeLayer(this.marcaPlay);
      }

      let iconGps = Leaflet.icon({
        iconUrl: '../../../assets/images/marca-blue.png',
        iconSize: [28, 41],
        iconAnchor:[14, 20],
        popupAnchor: [-1, -30]
      });
      
      this.marcaPlay = new Leaflet.marker([this.historial[this.numRuta].lat, this.historial[this.numRuta].lng], {icon:iconGps, rotationAngle: this.historial[this.numRuta].angulo }).addTo(this.map);

      if( this.numRuta == 0 ){
        this.map.setView([this.historial[this.numRuta].lat, this.historial[this.numRuta].lng ], 18 );
      }else{
        this.map.setView([this.historial[this.numRuta].lat, this.historial[this.numRuta].lng ]);
      }
      this.numRuta++;
    },this.intervalTimepo);
  }

  getObjeto(){

    // const loading = await this.loadingCtrl.create({
    //   message: 'Ca...',
    //   duration: 3000,
    //   spinner: 'circles',
    // });

    this._api.getObjeto( {imei:this.imei} ).subscribe((data:any)=>{
      console.log( data );
      this.objeto = data.data[0];
    });
  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertCtrl.create({      
      subHeader: 'Mensaje',
      message: mensaje,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async historialObjeto(){

    let loading = await this.loadingCtrl.create({
      message:"Cargando reporte, espere un momento.",
      spinner:"circles",
      duration: 3000
    });

    loading.present();

    this._api.historialObjeto({ finicial:this.inicial, ffinal:this.final, imei:this.imei }).subscribe((data:any)=>{
      loading.dismiss();
      console.log(data);
      this.historial= data.data;
      if( data.data ){
        if( data.data.length < 2  ){
          this.presentAlert( "No se encontraron datos" );
          this.navCtrl.back();
        }
      }

      if( data.status == 0 ){
        this.presentAlert( data.mensaje );
        this.navCtrl.back();
        return;
      }
      this.creaLineasRecorrido( data.data );
    },(err:any)=>{
      loading.dismiss();
    });
  }

  leafletMap(){

    let osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		let osmAttrib = '&copy; OSM - contributors';
		let osm  = Leaflet.tileLayer(osmUrl, { maxZoom: 21, attribution: osmAttrib });
    this.map = Leaflet.map('map', { attributionControl: false, center: [this.lat, this.lng], zoom: 16 });

    const here = {
      id: '6AJleReU2wy5FIYdcHUZ',
      code: '2sYGPV-IeanNImtVlcmNpw'
    }
    const style = 'normal.day';

    Leaflet.control.layers({
      'OSM': osm.addTo(this.map),
      "Humanitarian": Leaflet.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { attribution: 'Map &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> | Tiles &copy; <a href=\"http://hot.openstreetmap.org\">Humanitarian OSM Team</a>', minZoom: 0, maxZoom: 18 }),
      'HERE': Leaflet.tileLayer(`https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}&ppi=320`,{
        attribution:"HERE",
        maxZoom:19
      }),
        'HERE HYBRID': Leaflet.tileLayer(`https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}`,{
        attribution: 'HERE hybrid',
        maxZoom: 19
      }),
      'Google Streets': Leaflet.tileLayer('http://www.google.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}', {
	      attribution: 'google',
	      maxZoom: 20,
      }),           
      "Google Traffic": Leaflet.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20,
          minZoom: 2,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      "Google Hybrid": Leaflet.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20
      })  
    }).addTo(this.map);

  }

  clearRuta( data:any ){    
    let unique = this.removeDuplicates(data,'lat', 'lng');
    console.log(unique);
  }

removeDuplicates(originalArray:any, prop:any, prop2:any) {
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
       lookupObject[originalArray[i][prop2]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}

removeDuplicatesOne(originalArray:any, prop:any) {
  var newArray = [];
  var lookupObject  = {};

  for(var i in originalArray) {
     lookupObject[originalArray[i][prop]] = originalArray[i];     
  }

  for(i in lookupObject) {
      newArray.push(lookupObject[i]);
  }
   return newArray;
}

//revisa los puntos sin velocidad y limpia duplicados
parking( data:any = [] ){
  let parking = data.filter( (p:any) =>{
    return p.speed < 4;
  });

  //eliminas duplicados
  let newParking = this.removeDuplicatesOne(parking, 'direccion');
  console.log("parking", newParking);

  return newParking;
}

creaLineasRecorrido( data:any ){    
    console.log("creaLineasRecorrido", data);
    // this.clearRuta(data);
    let unique = this.removeDuplicates(data,'lat', 'lng');
    
    let num = 0; 
    
    setTimeout(() => {
      
      for( let unidad of unique ){
        
        num++;   
        this.polilineaData.push( [ unidad.lat, unidad.lng ] );   
        
        
        if( unique.length == num ){        
          
          this.addMarcas(data);

          if( this.linea ){
            this.map.removeLayer(this.linea);
          }
          this.linea = new Leaflet.polyline(this.polilineaData, {color: '#2b3643', weight:3}).addTo(this.map);  
          
          this.map.fitBounds( this.polilineaData, {padding: [50,50]} );  
        }
  
      }
    }, 500);

  }

  addMarcas( marcas:any ){

    let markesParking = this.parking(marcas);

    ///inicia la ruta

    let iconGpsInical = Leaflet.icon({
      iconUrl: '../../../assets/images/route-start.svg',
      iconSize: [28, 41],
      iconAnchor:[14, 20],
      popupAnchor: [-1, -30]
    });

    let markerInicial = new Leaflet.marker([marcas[0].lat, marcas[0].lng], {icon:iconGpsInical})
                        .bindPopup(`<strong>Dirección:</strong>${marcas[0].direccion}
                                    <br><strong>Ubicación:</strong>
                                    <br><strong>Velocidad: </strong>${this.speed( marcas[0].speed )}Km/h<br>
                                    <strong>Status: </strong>${this.timeConversion( marcas[0].status_motor )}<br>
                                    <strong>Motor:</strong>${this.motor(marcas[0].acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( marcas[0].timestamp)}`).addTo(this.map);

                                    markerInicial.bindTooltip('Inicio', {permanent:true, offset: Leaflet.point({x: -10, y: 0}), direction:'left' });                                    
    

    // Marca finaliza ruta
    let iconGpsFinal = Leaflet.icon({
      iconUrl: '../../../assets/images/route-end.svg',
      iconSize: [28, 41],
      iconAnchor:[14, 20],
      popupAnchor: [-1, -30]
    });    

    let markerFinal = new Leaflet.marker([marcas[marcas.length - 1].lat, marcas[marcas.length - 1].lng], {icon:iconGpsFinal})
                        .bindPopup(`<strong>Dirección:</strong>${marcas[marcas.length - 1].direccion}
                                    <br><strong>Ubicación:</strong>
                                    <br><strong>Velocidad: </strong>${this.speed( marcas[marcas.length - 1].speed )}Km/h<br>
                                    <strong>Status: </strong>${this.timeConversion( marcas[marcas.length - 1].status_motor )}<br>
                                    <strong>Motor:</strong>${this.motor(marcas[marcas.length - 1].acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( marcas[marcas.length - 1].timestamp)}`).addTo(this.map);

                                    markerFinal.bindTooltip('Fin', {permanent:true, offset: Leaflet.point({x: -10, y: 0}), direction:'left' });                                    

    for( let m of markesParking ){

      let iconGps = Leaflet.icon({
        iconUrl: '../../../assets/images/route-stop.svg',
        iconSize: [28, 41],
        iconAnchor:[14, 20],
        popupAnchor: [-1, -30]
      });

      let marker = new Leaflet.marker([m.lat, m.lng], {icon:iconGps})
                        .bindPopup(`<strong>Dirección:</strong>${m.direccion}
                                    <br><strong>Ubicación:</strong>
                                    <br><strong>Velocidad: </strong>${this.speed( m.speed )}Km/h<br>
                                    <strong>Status: </strong>${this.timeConversion( m.status_motor )}<br>
                                    <strong>Motor:</strong>${this.motor(m.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( m.timestamp)}`).addTo(this.map);

    }
  }

  getTIMESTAMP(timestamp:any) {
    let months:any = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    let date:any = new Date(timestamp);
    let year:any = date.getFullYear();
    let month:any = ("0"+(date.getMonth()+1)).substr(-2);
    let day = ("0"+date.getDate()).substr(-2);
    let hour = ("0"+date.getHours()).substr(-2);
    let minutes = ("0"+date.getMinutes()).substr(-2);
    let seconds = ("0"+date.getSeconds()).substr(-2);

    return months[month-1]+" "+day+", "+year+" "+hour+":"+minutes+":"+seconds;
  }

  validaAngulo( status:any, angulo:any ){    
    //console.log( status, angulo );
    if( status == undefined || status == 2 ){      
      return angulo;
    }

    if( status == 1 ){
      return angulo;
    }else{
      return 0;
    } 
  }
  motor( status:any ){

    if( status == "0" ){
      return "Apagado";
    }else if( status == "1" ){
      return "Encendido";
    }else{
      return "!";
    }
  }

  speed( speed:any ){
    if( speed ){   
      let newSpeed = parseFloat( speed ) / 0.62137;  
      return newSpeed.toFixed(1);
    }else{
      return "0";
    }
  }

  back(){
    clearInterval(this.interval);
    this.navCtrl.back();
  }

  timeConversion(millisec:any) {	
    if( millisec ){				
        let seconds = (millisec / 1000).toFixed(1);
        var minutes = (millisec / (1000 * 60)).toFixed(1);
        var hours = (millisec / (1000 * 60 * 60)).toFixed(1);
        var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

        if (parseFloat(seconds) < 60) {
            return seconds + " seg";
        } else if (parseFloat(minutes) < 60) {
            return minutes + " min";
        } else if (parseFloat(hours) < 24) {
            return hours + " hrs";
        } else {
            return days + "Días"; 
        }
    }else{
        return 0;
    }
  }

  playRuta(){
    this.play = true;
    this.pause = false;
    this.tiempoReproduccion();
  }

  stopRuta(){
    clearInterval(this.interval);
    this.numRuta = 0;
    this.stop = true;
    this.play = false;
    this.pause = false;
  }


  handleChange(event:any ){
    console.log(event.detail);
    clearInterval(this.interval);
    this.intervalTimepo = this.tiempoInterval(event.detail.value);
    this.tiempoReproduccion();
  }

  tiempoInterval( numero:any ){    

    if (numero == '1') {
      return 2000;
    }else if (numero == '2') {
      return 1500;
    }else if (numero == '3') {
      return 1000;
    }else if (numero == '4') {
      return 500;
    }else{
      return 2000;
    }

  }

  pausaRuta(){
    clearInterval( this.interval );
    this.play  = false;
    this.pause = true;
    this.stop = false;
  }

}
