import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MapaReportesPageRoutingModule } from './mapa-reportes-routing.module';

import { MapaReportesPage } from './mapa-reportes.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MapaReportesPageRoutingModule
  ],
  declarations: [MapaReportesPage]
})
export class MapaReportesPageModule {}
